<template>
  <div>
    <b-row class="match-height">
      <b-col
        v-for="stat in dashboardStats"
        :key="stat.subtitle"
        xl="2"
        md="4"
        sm="6"
        class="cursor-pointer"
        @click="$router.push({ name: stat.link })"
      >
        <statistic-card-vertical
          :icon="stat.icon"
          :statistic="stat.title"
          :statistic-title="stat.subtitle"
          :color="stat.color"
        />
      </b-col>
      <b-col lg="12">
        <sales-report-card />
      </b-col>
      <b-col lg="12">
        <sales-transactions-table />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import SalesTransactionsTable from '@/views/sales/SalesTransactionsTable.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import SalesReportCard from '@core/components/statistics-cards/SalesReportCard.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    BCol,
    BRow,
    SalesTransactionsTable,
    StatisticCardVertical,
    SalesReportCard,
  },
  data() {
    return {
      dashboardData: {},
      dashboardStats: [],
      dashboadType: 'admin',
    }
  },
  created() {
    this.fetchProducts({ meta: { pagination: { per_page: 1 } } })
      .then(response => {
        const stat = {
          title: response.data.meta.pagination.total_objects,
          subtitle: 'Productos',
          icon: 'BoxIcon',
          color: 'success',
          link: 'products-list',
        }
        this.dashboardStats.push(stat)
      })
    this.fetchCategories({ meta: { pagination: { per_page: 1 } } })
      .then(response => {
        const stat = {
          title: response.meta.pagination.total_objects,
          subtitle: 'Categorías',
          icon: 'BookmarkIcon',
          color: 'info',
          link: 'categories-list',
        }
        this.dashboardStats.push(stat)
      })
    this.fetchOrders()
  },
  methods: {
    ...mapActions('walleats', ['fetchWalleats']),
    ...mapActions('establishments', ['fetchEstablishments']),
    ...mapActions('products', ['fetchProducts']),
    ...mapActions('categories', ['fetchCategories']),
    ...mapActions('orders', ['fetchOrders']),
    ...mapActions('dashboard', ['fetchDashboard']),
  },
}
</script>

<style lang="scss" scoped>

  .small-title .card-header {
    font-size: 12px; /* Adjust the font size as needed */
  }
  .light-success {
    color: #00cfe8; /* Replace with the desired color value */
    font-weight: bold;
  }
  .small-subtitles .card-subtitle {
    font-size: 5px; /* Adjust the font size as needed */
  }

</style>

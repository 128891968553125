<template>
  <b-card no-body class="mb-0">
    <div class="m-2">
      <header
        class="d-flex flex-wrap align-content-center"
        :class="{
          'justify-content-end': userData.role_name === 'customer',
          'justify-content-between': userData.role_name !== 'customer',
        }"
      >
        <h2 class="grow" v-if="userData.role_name !== 'customer'">Facturas</h2>

        <div class="d-flex align-items-center justify-content-end">
          <b-button
            v-if="userData.role_name !== 'customer'"
            variant="info"
            class="mr-1"
            size="sm"
            :to="{
              name: 'payee-list',
            }"
          >
            <span class="text-nowrap">Beneficiarios</span>
          </b-button>
          <b-button
            v-if="userData.role_name !== 'customer'"
            variant="info"
            class="mr-1"
            size="sm"
            :to="{
              name: 'establishment-csds',
              params: { id: establishmentId },
            }"
          >
            <span class="text-nowrap">Certificados</span>
          </b-button>
          <b-button
            class="add-button-rounded ml-auto"
            variant="success"
            :to="{ name: 'generate-online-invoice' }"
          >
            <feather-icon icon="PlusIcon" size="18" />
          </b-button>
        </div>
      </header>
      <b-row class="mt-1">
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <small>
            Puedes generar tus facturas CFDI 4. Para generar una nueva
            necesitas dar de alta tu Certificado de Sello Digital. 
            <br><br>
            El costo de cada factura es de $1.00 MXN.
          </small>
        </b-col>
      </b-row>
    </div>

    <b-list-group class="invoice-list px-2">
      <b-list-group-item
        v-for="invoice in invoices"
        :key="invoice.id"
        class="pb-1 d-flex align-items-center"
      >
        <div class="invoice-flex grow">
          <div class="badges">
            <b-badge
              :variant="
                invoice.active_status === 'active' ? 'success' : 'danger'
              "
              v-for="(order, index) in invoice.orders"
              :key="index"
            >
              {{ order.auto_inc_folio }}
            </b-badge>
          </div>
          <p class="text-break text-sm">Total: ${{ invoice.tax_amount }}</p>
          <p class="text-break text-sm">Subtotal: ${{ invoice.amount }}</p>
        </div>

        <div class="ml-auto">
          <b-button
            variant="primary"
            size="sm"
            @click="handleSendByEmail(invoice)"
          >
            Enviar
          </b-button>
        </div>
      </b-list-group-item>
    </b-list-group>

    <b-table
      class="position-relative invoice-table"
      :items="invoices"
      :fields="tableColumns"
      responsive
      primary-key="id"
      show-empty
      empty-text="No existen registros aún"
    >
      <template #cell(orders)="data">
        <b-badge
          :variant="data.item.active_status === 'active' ? 'success' : 'danger'"
          size="sm"
          class="mx-1"
          v-for="(order, index) in data.item.orders"
        >
          {{ order.auto_inc_folio }}
        </b-badge>
      </template>

      <template #cell(send_by_email)="data">
        <b-button
          variant="primary"
          size="sm"
          @click="handleSendByEmail(data.item)"
        >
          Enviar
        </b-button>
      </template>

      <!-- <template #cell(download)="data">
        <b-button
          variant="outline-primary"
          size="sm"
          @click="handleDownload(data.item)"
        >
          Descargar
        </b-button>
      </template> -->
    </b-table>
    <div class="mx-2 mb-2 mt-1">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-start
          "
        >
          <span class="text-muted"
            >Mostrando {{ pagination.total_objects }} de
            {{ invoiceCount }} registros</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-end
          "
        >
          <b-pagination
            v-model="pagination.page"
            :total-rows="pagination.total_objects"
            :per-page="pagination.per_page"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="
              (value) => {
                handlePagination(value);
              }
            "
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      tableColumns: [
        {
          label: "Ordenes facturadas",
          key: "orders",
          sortable: true,
        },
        {
          label: "Subtotal",
          key: "amount",
          sortable: true,
        },
        {
          label: "Total",
          key: "tax_amount",
          sortable: true,
        },
        {
          label: "Enviar por email",
          key: "send_by_email",
          sortable: true,
        },
      ],
      establishmentId: null,
    };
  },
  computed: {
    ...mapGetters("invoices", ["invoices", "pagination", "invoiceCount"]),
  },
  beforeMount() {
    this.fetchInvoices();
    const establishment = this.userData.scoped_roles.filter(
      (x) => x.role_resource_type === "Establishment"
    );
    this.establishmentId =
      establishment.length > 0 ? establishment[0].role_resource_id : null;
  },
  methods: {
    ...mapActions("invoices", [
      "fetchInvoices",
      "registerInvoice",
      "sendByEmail",
      "downloadInvoice",
    ]),
    handlePagination(value) {
      this.fetchInvoices({
        meta: {
          pagination: {
            page: value,
            per_page: this.pagination.per_page,
          },
        },
      });
    },
    handleSendByEmail(item) {
      this.$swal({
        title: "Enviando por correo...",
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
        },
      });
      this.sendByEmail({
        invoice_id: item.id,
      })
        .then((res) => {
          this.$swal.close();
          this.$swal({
            title: "Factura enviada!",
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        })
        .catch((err) => {
          this.$swal({
            title: "Error!",
            text: "Ocurrió un error al enviar la factura",
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        });
    },
    // handleDownload(item){
    //   this.downloadInvoice(item.id)
    // }
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.add-button-rounded {
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.invoice-list {
  display: block;

  @media (min-width: 768px) {
    display: none;
  }
}

.invoice-table {
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
}

.badges {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.invoice-flex {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
</style>

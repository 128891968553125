<template>
  <div>
    <b-row class="match-height">
      <b-col
        v-if="userData.customer"
        sm="12"
        md="6"
        lg="5"
        xl="4"
      >
        <current-balance :user-data="userData" />
        <add-your-first-bracelet
          v-if="userData.customer && userData.customer.bracelets_count === 0"
        />
      </b-col>
      <b-col
        cols="12"
        md="6"
        lg="7"
        xl="8"
      >
        <customer-transactions
        />
        <!-- <transactions-timeline :orders="orders" /> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import {
  BRow, BCol,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import AddYourFirstBracelet from '@/@core/components/BraceletWizzard.vue'
import CustomerTransactions from '@/@core/components/CustomerTransactions.vue'
import CurrentBalance from '@/@core/components/CurrentBalance.vue'

export default {
  components: {
    BCol,
    BRow,
    AddYourFirstBracelet,
    CustomerTransactions,
    CurrentBalance,
  },
  data() {
    return {
      userData: {},
    }
  },
  mounted() {
    this.customerBalanceTransactionsConnection = new WebSocket(
      `${process.env.VUE_APP_WSS_URL}/cable?token=${this.userData.token}`,
    )
    sessionStorage.setItem('wsCustomerBalanceTransactionsConnection', JSON.stringify(this.customerBalanceTransactionsConnection))

    this.customerBalanceTransactionsConnection.onmessage = event => {
      const messagex = JSON.parse(event.data)
      if (typeof messagex.message === 'object') {
        this.fetchCustomerBalance({ id: this.userData.customer.id })
      }
    }

    this.customerBalanceTransactionsConnection.onopen = () => {
      // eslint-disable-next-line
      console.log('Successfully connected to the echo websocket server...')

      // eslint-disable-next-line
      this.customerBalanceTransactionsConnection.send(JSON.stringify({ "command": "subscribe","identifier":"{\"channel\":\"ApplicationCable::MyChannel\"}" }))
    }
  },
  destroyed() {
    this.customerBalanceTransactionsConnection.close()
  },
  created() {
    this.userData = getUserData()
  },
  methods: {
    ...mapActions('walleats', ['fetchCustomerBalance']),
    ...mapActions('dashboard', ['fetchDashboard']),

  },
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <b-row>
    <b-col lg="12">
      <sales-report-card />
    </b-col>
  </b-row>
</template>

<script>
import { mapActions } from 'vuex'
import VueApexCharts from 'vue-apexcharts'
import SalesReportCard from '@core/components/statistics-cards/SalesReportCard.vue'
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    SalesReportCard,
    VueApexCharts,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      dashboadType: 'establishment',
    }
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<template>
  <div>
    <b-card
      class="balance"
      bg-variant="transparent"
    >
      <b-row class="d-flex">
        <feather-icon
          class="ml-1"
          :icon=" isBalanceVisible ? 'EyeIcon' : 'EyeOffIcon'"
          @click="toggleBalanceVisible(!isBalanceVisible)"
        />
      </b-row>
      <div class="d-flex">
        <b-col>
          Saldo a favor
        </b-col>
        <b-col>
          Saldo por pagar
        </b-col>
      </div>
      <b-row class="d-flex justify-content-around mt-1">
        <b-col>
          <h1
            v-if="isBalanceVisible"
            class="display-5"
          >
            $ {{ totalBalance | money }}
          </h1>
          <h1
            v-else
            class="display-5"
          >
            $ *****
          </h1>
        </b-col>
        <b-col>
          <h1
            v-if="isBalanceVisible"
            class="display-6"
          >
            $ {{ totalLoanBalance | money }}
          </h1>
          <h1
            v-else
            class="display-6"
          >
            $ *****
          </h1>
        </b-col>
      </b-row>
      <b-row class="d-flex justify-content-around mt-1">
        <b-col>
          <!--
          <b-button
            block
            pill
            variant="success"
            @click="$bvModal.show('deposit-warning')"
          >
            Depositar
          </b-button>
        -->
        </b-col>
      </b-row>
      <b-modal
        id="deposit-warning"
        title="Antes de continuar"
        class="modal-content"
        hide-header-close
        hide-footer
      >
        <b-row>
          <span style="color: white; text-justify: justify;">
            ¿Quieres realizar un depósito en Walleat? Tus fondos solo podrán ser utilizados con nuestras tarjetas o brazalete. Si deseas hacer una compra en línea, no es necesario que realices un depósito...
          </span>
        </b-row>
        <div style="height: 20px;"></div>
        <b-row>
          <span style="color: orange; display: flex; justify-content: center;">
            ¿Deseas continuar?
          </span>
        </b-row>
        <div style="height: 20px;"></div>
        <div class="d-flex mt-2">
          <b-col cols="6">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="danger"
              @click="$bvModal.hide('deposit-warning')"
            >
              Cancelar
            </b-button>
          </b-col>
          <b-col cols="6">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="success"
              :to="{ name: 'choose-payment-gateway' }"
            >
              Continuar
            </b-button>
          </b-col>
        </div>
      </b-modal>    
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BCard,
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  components: {
    BRow, BCol, BButton, BCard,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      totalBalance: null,
      totalLoanBalance: null,
    }
  },
  beforeMount() {
    this.fetchCustomerBalance({ id: this.userData.customer.id })
      .then(response => {
        let balance = 0
        let loanBalance = 0
        this.accounting.forEach(element => {
          balance+=Number(element.balance)
          loanBalance+=Number(element.loan_balance)
        });
        this.totalBalance = balance
        this.totalLoanBalance = loanBalance
      })
  },
  computed: {
    ...mapGetters('appConfig', [
      'isBalanceVisible',
    ]),
    ...mapGetters('walleats', ['accounting']),
  },
  methods: {
    ...mapMutations('appConfig', [
      'toggleBalanceVisible',
    ]),
    ...mapActions('walleats', ['fetchCustomerBalance']),
  },
}
</script>

<style lang="scss" scoped>
.display-5 {
  font-size: 2.4rem;
}
.display-6 {
  font-size: 2.4rem;
  color:brown;
}
</style>

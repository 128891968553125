<template>
  <div>
    store_clerk
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

</style>

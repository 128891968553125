<template>
  <div>
    <!--<Mapbox />-->
    <b-row class="match-height">
      <b-col lg="8">
        <establishment-admin-statistics :data="dashboardStats" />
      </b-col>
      <b-col
        lg="4"
        @click="toStablishment()"
      >
        <statistic-card-vertical
          icon="ShoppingBagIcon"
          :statistic="Number(dashboardData.store_count) || 0"
          :statistic-title="`Punto${dashboardData.store_count > 1 ? 's' : ''} de venta`"
          color="primary"
          class="pt-1 cursor-pointer"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <sales-transactions-table />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import { mapActions, mapMutations } from 'vuex'
import EstablishmentAdminStatistics from '@/@core/components/EstablishmentAdminStatistics.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import SalesTransactionsTable from '@/views/sales/SalesTransactionsTable.vue'

// import Mapbox from '@/components/Mapbox.vue'

export default {
  components: {
    BCol,
    BRow,
    EstablishmentAdminStatistics,
    StatisticCardVertical,
    SalesTransactionsTable,
    // Mapbox,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      dashboardData: {},
      dashboardStats: [],
      fields: [
        {
          key: '#',
          label: '#',
        },
        {
          key: 'created_at',
          label: 'Fecha',
        },
        {
          key: 'payment_type',
          label: 'Tipo de pago',
        },
        {
          key: 'iva',
          label: 'IVA',
          class: 'text-right',
        },
        {
          key: 'subtotal',
          label: 'Subtotal',
          class: 'text-right',
        },
        {
          key: 'total',
          label: 'Total',
          class: 'text-right',
        },
      ],
    }
  },
  created() {
    this.fetchDashboard()
      .then(response => {
        this.dashboardData = response
        const labels = {
          daily: 'Hoy',
          weekly: 'Semana en curso',
          monthly: 'Mes en curso',
          total_loans: 'Total de ventas a crédito',
          monthly_loans: 'Ventas a crédito del día',
          monthly_avg_orders: 'Ticket promedio',
        }
        Object.keys(response.order_totals).forEach(x => {
          const stat = {
            title: `$ ${Number(response.order_totals[x]).toFixed(2)}`,
            subtitle: labels[x],
            icon: 'TrendingUpIcon',
            color: 'light-success',
            customClass: 'mb-1',
          }
          this.dashboardStats.push(stat)
        })
      })
    this.fetchOrders()
  },
  methods: {
    ...mapActions('dashboard', ['fetchDashboard']),
    ...mapActions('orders', ['fetchOrders']),
    ...mapMutations('orders', ['setOrders']),
    toStablishment() {
      this.$router.push({
        name: 'establishment-view',
        params: {
          id: this.userData.scoped_roles.filter(x => x.role_resource_type === 'Establishment')[0].role_resource_id,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>

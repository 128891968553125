<template>
  <div>
    <div>

      <h1>
        Reporte de ventas general
      </h1>
      <small>
        Última actualización {{ Date.now() | date }}
      </small>
    </div>

    <b-card>

      <label for="frequency">Seleccionar un periodo actual:</label>
      <select id="frequency" v-model="selectedFrequency" @change="fetchData">
        <option value="daily">Día</option>
        <option value="weekly">Semana</option>
        <option value="monthly">Mes</option>
        <option value="yearly">Año en curso</option>
        <option value="all">Desde el inicio</option>
      </select>
    </b-card>

    <statistics :data="salesStats" />

    <b-card>
      <swiper
        ref="mySwiper"
        class="swiper-navigations h-100"
        :options="swiperOptions"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      >
        <swiper-slide>
          <b-card-title>
            Ventas por método de pago
          </b-card-title>
          <div class="px-5">
            <vue-apex-charts
              height="300"
              :options="salesPaymentTypeOptions"
              :series="chartSeries"
              class="mt-2"
            />
          </div>
        </swiper-slide>

        <swiper-slide>
          <b-card-title>
            Ventas por forma de compra
          </b-card-title>
          <div class="px-5">
            <vue-apex-charts
              height="300"
              :options="salesModeTypeOptions"
              :series="chartSeries"
              class="mt-4"
            />
          </div>
        </swiper-slide>

        <swiper-slide>
          <b-card-title>
            Información general de ventas
          </b-card-title>
          <div class="px-5">
            <vue-apex-charts
              height="320"
              :options="salesOtherTypeOptions"
              :series="chartSeries"
              class="mt-4"
            />
          </div>
        </swiper-slide>

        <swiper-slide>
          <b-card-title class="mb-1" style="font-size: 14px;">
            Reporte de ventas tiempo aire y pago de servicios
          </b-card-title>
          <b-card-text
            v-for="salesInfo in storeStats.phoneBill"
            :key="salesInfo.subtitle"
            class="text-muted font-small-2 mb-0 ml-5"
          >
            <span class="custom-span">{{ salesInfo.title }}</span
            ><span class="custom-span-white"> {{ salesInfo.subtitle }}.</span>
          </b-card-text>

          <b-card-title class="mt-3 mb-1" style="font-size: 14px;">
            Reporte de inventario
          </b-card-title>
          <b-card-text
            v-for="salesInfo in storeStats.inventory"
            :key="salesInfo.subtitle"
            class="text-muted font-small-2 mb-0 ml-5"
          >
            <span class="custom-span">{{ salesInfo.title }}</span
            ><span class="custom-span-white"> {{ salesInfo.subtitle }}.</span>
          </b-card-text>
        </swiper-slide>
        <div
          slot="button-next"
          class="swiper-button-next"
        />
        <div
          slot="button-prev"
          class="swiper-button-prev"
        />
        <div
          slot="pagination"
          class="swiper-pagination"
        />
      </swiper>
    </b-card>
  </div>

</template>

<script>
import { mapActions } from 'vuex'
import VueApexCharts from 'vue-apexcharts'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import Statistics from '@core/components/CustomerStatistics.vue'
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import 'swiper/css/swiper.css'

export default {
  components: {
    VueApexCharts,
    Statistics,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      selectedFrequency: 'daily',
      totalSales: '0',
      totalMargin: '0',
      salesCount: '0',
      avgTicket: '0',
      avgHistoricalTicket: '0',
      salesStats: {
        totalSales: {
          title: '0',
          subtitle: 'Ventas totales',
          icon: 'TrendingUpIcon',
          color: 'light-success',
          customClass: 'mb-1',
        },
        totalProfit: {
          title: '0',
          subtitle: 'Ganancias totales',
          icon: 'ShoppingBagIcon',
          color: 'light-info',
          customClass: 'mb-1',
        },
      },
      storeStats: {
        sales: [
          { title: '0', subtitle: 'Ventas', icon: 'TrendingUpIcon', color: 'light-success', customClass: 'mb-1' },
          { title: '0', subtitle: 'Ganancia', icon: 'TrendingUpIcon', color: 'light-success', customClass: 'mb-1' },
          { title: '0', subtitle: 'Número de ventas', icon: 'TrendingUpIcon', color: 'light-success', customClass: 'mb-1' },
          { title: '0', subtitle: 'Actual', icon: 'TrendingUpIcon', color: 'light-success', customClass: 'mb-1' },
          { title: '0', subtitle: 'Histórico', icon: 'TrendingUpIcon', color: 'light-success', customClass: 'mb-1' },
        ],
        salesFiltered: [
          { title: '0', subtitle: 'No de ventas', icon: 'TrendingUpIcon', color: 'light-success', customClass: 'mb-1' },
          { title: '0', subtitle: 'Ticket promedio actual', icon: 'TrendingUpIcon', color: 'light-success', customClass: 'mb-1' },
          { title: '0', subtitle: 'Ticket promdio histórico', icon: 'TrendingUpIcon', color: 'light-success', customClass: 'mb-1' },
        ],
        paymentType: [
          { title: '0', subtitle: 'Efectivo', icon: 'TrendingUpIcon', color: 'light-success', customClass: 'mb-1' },
          { title: '0', subtitle: 'Walleat pago', icon: 'TrendingUpIcon', color: 'light-success', customClass: 'mb-1' },
          { title: '0', subtitle: 'Tarjeta bancaria', icon: 'TrendingUpIcon', color: 'light-success', customClass: 'mb-1' },
          { title: '0', subtitle: 'Puntos Walleat', icon: 'TrendingUpIcon', color: 'light-success', customClass: 'mb-1' },
          { title: '0', subtitle: 'Depósitos bancarios', icon: 'TrendingUpIcon', color: 'light-success', customClass: 'mb-1' },
        ],
        orderMode: [
          { title: '0', subtitle: 'En tienda', icon: 'TrendingUpIcon', color: 'light-success', customClass: 'mb-1' },
          { title: '0', subtitle: 'En línea', icon: 'TrendingUpIcon', color: 'light-success', customClass: 'mb-1' },
          { title: '0', subtitle: 'Pre-ventas', icon: 'TrendingUpIcon', color: 'light-success', customClass: 'mb-1' },
        ],
        phoneBill: [
          { title: '0', subtitle: 'Ventas tiempo aire', icon: 'TrendingUpIcon', color: 'light-success', customClass: 'mb-1' },
          { title: '0', subtitle: 'Ventas pago de servicios', icon: 'TrendingUpIcon', color: 'light-success', customClass: 'mb-1' },
          { title: '0', subtitle: 'Utilidad venta de tiempo aire', icon: 'TrendingUpIcon', color: 'light-success', customClass: 'mb-1' },
          { title: '0', subtitle: 'Utilidad  pago de servicios', icon: 'TrendingUpIcon', color: 'light-success', customClass: 'mb-1' },
        ],
        inventory: [
          { title: '0', subtitle: 'Valor del inventario', icon: 'TrendingUpIcon', color: 'light-success', customClass: 'mb-1' },
          { title: '0', subtitle: 'Utilidad del inventario', icon: 'TrendingUpIcon', color: 'light-success', customClass: 'mb-1' },
          { title: '0', subtitle: 'Compras de inventario', icon: 'TrendingUpIcon', color: 'light-success', customClass: 'mb-1' },
          { title: '0', subtitle: 'Perdidas de inventario', icon: 'TrendingUpIcon', color: 'light-success', customClass: 'mb-1' },
          { title: '0', subtitle: 'Correcciones de inventario', icon: 'TrendingUpIcon', color: 'light-success', customClass: 'mb-1' },
        ],
      },
      salesPaymentTypeOptions: {
        // ApexCharts options
        chart: {
          type: 'donut',
        },
        series: [],
      },
      salesModeTypeOptions: {
        // ApexCharts options
        chart: {
          type: 'donut',
        },
        series: [],
      },
      salesOtherTypeOptions: {
        // ApexCharts options
        chart: {
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          }
        },
        dataLabels: {
          enabled: true
        },
        series: [],
      },
      chartSeries: [],
    }
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  beforeMount() {
    this.fetchDashboard({ storeId: this.dashboardType == 'establishment' || this.dashboardType == 'admin' ? null : this.$route.params.id, frequency: this.selectedFrequency })
    .then(response => {
      this.updateStats(response)
      this.initChartData()
    })
    .catch(error => {
      console.log(error)
    })
  },
  mounted() {
    this.initChartData()
  },
  methods: {
    ...mapActions('stores', ['fetchDashboard']),
    fetchData() {
      this.fetchDashboard({ storeId: this.dashboardType == 'establishment' || this.dashboardType == 'admin' ? null : this.$route.params.id, frequency: this.selectedFrequency })
      .then(response => {
        this.updateStats(response)
        this.initChartData()
      })
      .catch(error => {
        console.log(error)
      })
    },
    updateStats(response) {
      const {
        sales_and_margins_report,
        payment_type_report,
        order_mode_report,
        phone_credits_and_bill_payments_sales_report,
        inventory_report
      } = response
      this.salesStats.totalSales.title = `${response.sales_and_margins_report.sales.toFixed(0)}`
      this.salesStats.totalProfit.title = `${response.sales_and_margins_report.margins.toFixed(0)}`

      this.storeStats.sales.forEach((item, index) => {
        if (item.subtitle === 'Número de ventas') {
          item.title = `${sales_and_margins_report[Object.keys(sales_and_margins_report)[index]].toFixed(0)}`
        } else {
          item.title = `$ ${sales_and_margins_report[Object.keys(sales_and_margins_report)[index]].toFixed(2)}`
          item.subtitle_new = `${item.subtitle} $ ${sales_and_margins_report[Object.keys(sales_and_margins_report)[index]].toFixed(2)}`
        }

        if (item.subtitle === 'Ventas') {
          this.totalSales = `${sales_and_margins_report[Object.keys(sales_and_margins_report)[index]].toFixed(2)}`
        }
        if (item.subtitle === 'Ganancia') {
          this.totalMargin = `${sales_and_margins_report[Object.keys(sales_and_margins_report)[index]].toFixed(2)}`
        }
      })

      this.storeStats.salesFiltered = this.storeStats.sales.filter(item => item.subtitle !== 'Ventas' && item.subtitle !== 'Ganancia');
    
      this.storeStats.paymentType.forEach((item, index) => {
        item.title = `$ ${payment_type_report[Object.keys(payment_type_report)[index]].toFixed(2)}`
        item.subtitle_new = `${item.subtitle} $ ${payment_type_report[Object.keys(payment_type_report)[index]].toFixed(2)}`
      })

      this.storeStats.orderMode.forEach((item, index) => {
        item.title = `$ ${order_mode_report[Object.keys(order_mode_report)[index]].toFixed(2)}`
        item.subtitle_new = `${item.subtitle} $ ${order_mode_report[Object.keys(order_mode_report)[index]].toFixed(2)}`
      })

      this.storeStats.phoneBill.forEach((item, index) => {
        item.title = `$ ${phone_credits_and_bill_payments_sales_report[Object.keys(phone_credits_and_bill_payments_sales_report)[index]].toFixed(2)}`
      })

      this.storeStats.inventory.forEach((item, index) => {
        item.title = `$ ${inventory_report[Object.keys(inventory_report)[index]].toFixed(2)}`
      })
    },

    // Initialize the chart data
    initChartData() {
      this.salesPaymentTypeOptions = {
        chart: {
          type: 'donut',
        },
        responsive: [
          {
            breakpoint: 500,
            options: {
              plotOptions: {
                bar: {
                  horizontal: false
                }
              },
              legend: {
                position: "bottom",
                horizontalAlign: "left",
              }
            }
          }
        ],
        series: this.storeStats.paymentType.map((payment) => {
          const amount = parseFloat(payment.title.replace('$', '').replace(',', ''))
          return amount
        }),
        labels: this.storeStats.paymentType.map((payment) => payment.subtitle_new),
      }
      this.salesModeTypeOptions = {
        chart: {
          type: 'donut',
        },
        responsive: [
          {
            breakpoint: 500,
            options: {
              plotOptions: {
                bar: {
                  horizontal: false
                }
              },
              legend: {
                position: "bottom",
                horizontalAlign: "left",
              }
            }
          }
        ],
        series: this.storeStats.orderMode.map((mode) => {
          const amount = parseFloat(mode.title.replace('$', '').replace(',', ''))
          return amount
        }),
        labels: this.storeStats.orderMode.map((mode) => mode.subtitle_new),
      }

      this.salesOtherTypeOptions = {
        chart: {
          stacked: true
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: false,
          }
        },
        responsive: [
          {
            breakpoint: 1000,
            options: {
              plotOptions: {
                bar: {
                  horizontal: false
                }
              },
              legend: {
                position: "bottom",
                horizontalAlign: "left"
              }
            }
          }
        ],
        series: [
          {
            name: 'Izquierda',
            data: [parseFloat(this.storeStats.salesFiltered[0].title.replace('$', '').replace(',', ''))],
            yAxis: 'left',
          },
          {
            name: 'Derecha',
            data: [0].concat(
              this.storeStats.salesFiltered.slice(1).map((mode) => {
                const amount = parseFloat(mode.title.replace('$', '').replace(',', ''));
                return amount;
              })
            ),
            yAxis: 'right',
          },
        ],
        yaxis: [
          {
            title: {
              text: "No ventas"
            },
          },
          {
            opposite: true,
            title: {
              text: "Ticket promedio"
            }
          }
        ],

        colors: ['#00cfe8','#d4d9d4'],
        labels: this.storeStats.salesFiltered.map((mode) => mode.subtitle),
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

  .small-title .card-header {
    font-size: 12px; /* Adjust the font size as needed */
  }
  .light-success {
    color: #00cfe8; /* Replace with the desired color value */
    font-weight: bold;
  }
  .small-subtitles .card-subtitle {
    font-size: 5px; /* Adjust the font size as needed */
  }
  .custom-span {
    font-weight: bold;
    color: #00cfe8;
  }

  .custom-span-white {
    font-weight: bold;
    color: #d4d9d4;
  }

  .chart-container {
    display: grid;
    grid-template-rows: min-content 1fr;
  }


</style>